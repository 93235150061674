var v = new Vue({
  el: '#hgn',
  data: {
  	date: null,
    schools: {},
    products: {},
    request: {},
    sale: {},
    productId: {},
    schoolId: {},
    barcode: '',
    clientStage: 0,
    customer: {},
    requestComments: null,
    customerSearch: {}
  },
  http: {
    root: '/root',
    headers: {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  },
  methods: {
    getRequestContent: function (){
      this.$http.get('/employee/request/content')
      .then(function (response) {
          this.request = response.data;
      });
    },
    getSaleContent: function (){
      this.$http.get('/employee/sale/content')
      .then(function (response) {
          this.sale = response.data;
      });
    },
    getCustomer: function (){
      this.$http.get('/api/getcustomer')
      .then(function (response) {
          this.customer = response.data;
          if (this.customer.id != null) {
            this.clientStage = 2;
          }
      });
    },
    getSchools: function (){
      this.$http.get('/api/schools')
      .then(function (response) {
          this.schools = response.data;
      });
    },
    getProductsBySchool: function (){
      this.$http.get('/api/products/school/'+this.schoolId)
      .then(function (response) {
          this.products = response.data;
      });
    },
    addRequestProduct: function (product){
      this.$http.post('/employee/request/addProduct', product)
      .then(function (response) {
          this.request = response.data;
      });
    },
    removeRequestProduct: function (product){
        this.$http.post('/employee/request/removeProduct', product)
        .then(function (response) {
            this.getRequestContent();
        });
    },
    decreaseRequestProduct: function(product) {
        if (product.qty > 1) {
            this.$http.post('/employee/request/decreaseProduct', product)
            .then(function(response) {
                this.getRequestContent();
            });
        }
    },
    cancelRequest: function() {
        this.request = {};
        this.schoolId = null;
        this.productId = null;
        this.$http.get('/employee/request/cancel');
    },
    listenBarcoder: function (){
      if (this.barcode != null && this.barcode.length > 12) {
        this.searchProductByBarcode(this.barcode);
      }
    },
    searchProductByBarcode: function (){
      this.$http.get('/api/products/barcode/'+this.barcode)
      .then(function (response) {
          var product = response.data;
          if (!$.isEmptyObject(product)) {
            this.addSaleProduct(product);
          }
          this.barcode = '';
      });
    },
    addSaleProduct: function (product){
      this.$http.post('/employee/sale/addProduct', product)
      .then(function (response) {
          this.sale = response.data;
      });
    },
    removeSaleProduct: function (product){
        this.$http.post('/employee/sale/removeProduct', product)
        .then(function (response) {
            this.getSaleContent();
        });
    },
    decreaseSaleProduct: function(product) {
        if (product.qty > 1) {
            this.$http.post('/employee/sale/decreaseProduct', product)
            .then(function(response) {
                this.getSaleContent();
            });
        }
    },
    cancelSale: function() {
        this.sale = {};
        this.$http.get('/employee/sale/cancel');
    },
    saveCustomer: function (customer){
      this.$http.post('/api/customers', customer)
      .then(function (response) {
          Vue.set(this.customer, 'id', response.data.id)
          if (this.customer.id) {
            this.clientStage = 2;
          }
      });
    },
    saveRequestComments: function (){
      this.$http.post('/employee/request/saveComments', {comments: this.requestComments})
      .then(function (response) {
      });
    }
  },
  ready: function() {
    //load initial data
    this.getSchools();
    this.getRequestContent();
    this.getSaleContent();
    this.getCustomer();

  	moment.locale("es");
  	var self = this;

    //set clock
  	setInterval(function() {
  		self.date = moment();
  	}, 1000);

    this.$watch('barcode', function () {
      Vue.nextTick(function () {
        this.$emit('barcode-update')
      }.bind(this))
    });

    this.$on('barcode-update', function () {
      this.listenBarcoder();
    })

    $(document).ready(function() {
      var pressed = false; 
      var chars = []; 
      var barcodeElement = document.getElementById('barcode');
      var bodyElement = document.getElementsByTagName('body');
      if (barcodeElement) {
        $(window).keypress(function(e) {
          var focusInput = document.activeElement;
          if (focusInput == barcodeElement || focusInput == bodyElement[0]) {
            if (e.which >= 48 && e.which <= 57) {
              e.preventDefault();
              chars.push(String.fromCharCode(e.which));
              self.barcode += String.fromCharCode(e.which);
            }
            //console.log(e.which + ":" + chars.join("|"));
            if (pressed == false) {
              setTimeout(function(){
                if (chars.length >= 13) {
                  var barcode = chars.join("");
                  //console.log("Barcode Scanned: " + barcode);
                      // assign value to some input (or do whatever you want)
                    }
                    chars = [];
                    pressed = false;
                  },500);
            }
            pressed = true;
          }
        });
      }

      // Set the height of divs with
      $(".full-window").css("height", $(window).height() - 136);
    });
  }
});